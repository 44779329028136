<template>
    <el-table-column
      align="left"
    >
      <template slot="header">
        <div>Парт - детализация цели в трёх уровнях.</div>
        <div>В зависимости от выбранной комбинации будут заполнены план и факт по цели.</div>
      </template>

      <el-table-column
        v-for="partIndex in 3"
        :key="partIndex"
        :label="'Уровень ' + partIndex"
        width="187"
      >
        <template v-slot="scope">
          <element-scale-part-single-table-column
            :scope="scope"
            :disabled="!isFieldAvailableForEdit(scope.row, 'scale.current_part_id')"
            :initial="partIndex===1"
            :part-name="'part_'+partIndex"
            @save-current_part_id="saveCurrentPart(scope, $event)"
          >
          </element-scale-part-single-table-column>
        </template>
      </el-table-column>


    </el-table-column>

</template>

<script>


import ClickToEdit from "@/components/ClickToEdit.vue";
import {ElementPropTableColumn} from "@/mixins/elementPropTableColumn";
import ElementScalePartSingleTableColumn
  from "@/componentsCompany/cherkizovo/tableColumnTemplates/card/parts/ElementScalePartSingleTableColumn.vue";

export default {
  name: 'element-scale-part-top-table-column',
  components: {ElementScalePartSingleTableColumn, ClickToEdit},
  mixins: [ElementPropTableColumn],

  props: {},
  computed: {},
  beforeMount() {

  },
  data() {
    return {

    }
  },
  mounted() {

  },
  methods: {
    saveCurrentPart(scope, chosenPartId){
      this.saveElementPropChange(scope.row, 'scale', {current_part_id:chosenPartId})
    },
  }
}

</script>

<style scoped lang="scss">
@import "@/assets/css/project-variables";

</style>
